import React, { Component } from "react";
import LandingContent from "../../Components/LandingContent/LandingContent";

class LandingPage extends Component {
  state = {};
  render() {
    return (
      <div>
        <LandingContent />
      </div>
    );
  }
}

export default LandingPage;
