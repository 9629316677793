import React from "react";
import CloseIconStyles from "./CloseIcon.module.css";

const Icon = () => {
  return (
    <div className={CloseIconStyles.Container}>
      <svg
        width="13"
        height="13"
        viewBox="0 0 10 10"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0.714286 10C0.531529 10 0.348772 9.93025 0.209263 9.79074C-0.0697545 9.51172 -0.0697545 9.05971 0.209263 8.78069L8.78069 0.209263C9.05971 -0.0697545 9.51172 -0.0697545 9.79074 0.209263C10.0698 0.488281 10.0698 0.94029 9.79074 1.21931L1.21931 9.79074C1.0798 9.93025 0.897042 10 0.714286 10Z" />
        <path d="M9.28571 10C9.10296 10 8.9202 9.93025 8.78069 9.79074L0.209263 1.21931C-0.0697545 0.94029 -0.0697545 0.488281 0.209263 0.209263C0.488281 -0.0697545 0.94029 -0.0697545 1.21931 0.209263L9.79074 8.78069C10.0698 9.05971 10.0698 9.51172 9.79074 9.79074C9.65123 9.93025 9.46847 10 9.28571 10Z" />
      </svg>
    </div>
  );
};

export default Icon;
